import { SnackbarHTMLProps } from '@rmwc/snackbar';
import { Typography } from '@rmwc/typography';
import graphql from 'babel-plugin-relay/macro';
import { useMemo } from 'react';
import { useSubscription } from 'react-relay';
import { Link, useHistory } from 'react-router-dom';
import { queue } from '../Snackbar/queue';
import { EpisodePublishedSnackbarHandlerSubscription, EpisodePublishedSnackbarHandlerSubscriptionResponse } from './__generated__/EpisodePublishedSnackbarHandlerSubscription.graphql';

import '@rmwc/snackbar/styles';

const subscription = graphql`
  subscription EpisodePublishedSnackbarHandlerSubscription {
    episodeUpdate {
      episode {
        id
        title
        state
        datePublished
        videos {
          __typename
          ... on YouTubeVideo {
            videoId
            title
          }
        }
        seasonPlacement {
          season {
            number
            placements {
              number
              episode {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const SECOND = 1000;
const FIVE_SECONDS = 5 * SECOND;
const MINUTE = 60 * SECOND;
const FIVE_MINUTES = 5 * MINUTE;

export default function EpisodePublishedSnackbarHandler() {
  const history = useHistory();
  const config = useMemo(() => ({
    subscription,
    onNext: (payload: EpisodePublishedSnackbarHandlerSubscriptionResponse | null | undefined) => {
      if (!payload) {
        return;
      }

      const { episode } = payload.episodeUpdate;

      if (episode.state !== 'PUBLISHED') {
        return;
      }

      const publishedDate = Date.parse(episode.datePublished!);

      // If this update happened and the episode was published more than five minutes ago it's probably not the publishment
      if (Date.now() - publishedDate > FIVE_MINUTES) {
        return;
      }

      const actions: (NotificationAction | SnackbarHTMLProps)[] = [];
      // We're pulling title from YouTubeVideo as the Episode is different on initial publish ATM.
      let title = null;

      for (const video of episode.videos) {
        if (video.__typename === 'YouTubeVideo') {
          title = video.title;
          actions.push({
            title: 'Watch On YouTube',
            onClick: () => {
              window.open(`https://youtube.com/watch?v=${video.videoId}`, '_blank', 'noreferrer');
            },
            theme: 'secondary',
          });
        }
      }

      actions.push({
        title: 'Go To Episode',
        onClick: () => {
          history.push(`/episodes/${episode.id}`);
        },
        theme: 'secondary',
      });

      queue.notify({
        actions,
        body: <Link
          to={`/episodes/${episode.id}`}
          style={{
            color: 'var(--mdc-theme-secondary)',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Link>,
        timeout: FIVE_SECONDS,
        title: <Typography use='headline6'>
          A new episode has been published:
        </Typography>,
      });
    },
    variables: {},
  }), [history]);
  useSubscription<EpisodePublishedSnackbarHandlerSubscription>(config);
  return <></>;
};
