/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WingTier = "TIER_1" | "TIER_2" | "TIER_3" | "%future added value";
export type WingNightAdmin_wingNightSubscriptionVariables = {};
export type WingNightAdmin_wingNightSubscriptionResponse = {
    readonly wingNightUpdate: {
        readonly id: string;
        readonly contributions: ReadonlyArray<{
            readonly datePaid: string;
            readonly amountCentsPaid: number;
            readonly " $fragmentRefs": FragmentRefs<"WingNightContributionFragment">;
        }>;
        readonly totalContributedCents: number;
        readonly totalUsedCents: number;
        readonly tiers: ReadonlyArray<{
            readonly bought: number;
            readonly eaten: number;
            readonly sauce: {
                readonly name: string;
            };
            readonly tier: WingTier;
        }>;
    };
};
export type WingNightAdmin_wingNightSubscription = {
    readonly response: WingNightAdmin_wingNightSubscriptionResponse;
    readonly variables: WingNightAdmin_wingNightSubscriptionVariables;
};



/*
subscription WingNightAdmin_wingNightSubscription {
  wingNightUpdate {
    id
    contributions {
      __typename
      datePaid
      amountCentsPaid
      ...WingNightContributionFragment
    }
    totalContributedCents
    totalUsedCents
    tiers {
      bought
      eaten
      sauce {
        name
      }
      tier
    }
  }
}

fragment WingNightContributionFragment on MonetaryContribution {
  __isMonetaryContribution: __typename
  __typename
  datePaid
  amountCentsPaid
  ... on TwitchBitsContribution {
    twitchAccount {
      displayName
      id
    }
  }
  ... on TwitchSubscriptionContribution {
    tier
    beneficiaryTwitchAccount {
      id
      displayName
    }
    payeeTwitchAccount {
      id
      displayName
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datePaid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountCentsPaid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalContributedCents",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalUsedCents",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tier",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "WingNightTier",
  "kind": "LinkedField",
  "name": "tiers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bought",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eaten",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WingSauce",
      "kind": "LinkedField",
      "name": "sauce",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WingNightAdmin_wingNightSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WingNight",
        "kind": "LinkedField",
        "name": "wingNightUpdate",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "contributions",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WingNightContributionFragment"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WingNightAdmin_wingNightSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WingNight",
        "kind": "LinkedField",
        "name": "wingNightUpdate",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "contributions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isMonetaryContribution"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TwitchAccount",
                    "kind": "LinkedField",
                    "name": "twitchAccount",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "TwitchBitsContribution",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TwitchAccount",
                    "kind": "LinkedField",
                    "name": "beneficiaryTwitchAccount",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TwitchAccount",
                    "kind": "LinkedField",
                    "name": "payeeTwitchAccount",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "TwitchSubscriptionContribution",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2898581a62cfb2baf708a64bebd0debd",
    "id": null,
    "metadata": {},
    "name": "WingNightAdmin_wingNightSubscription",
    "operationKind": "subscription",
    "text": "subscription WingNightAdmin_wingNightSubscription {\n  wingNightUpdate {\n    id\n    contributions {\n      __typename\n      datePaid\n      amountCentsPaid\n      ...WingNightContributionFragment\n    }\n    totalContributedCents\n    totalUsedCents\n    tiers {\n      bought\n      eaten\n      sauce {\n        name\n      }\n      tier\n    }\n  }\n}\n\nfragment WingNightContributionFragment on MonetaryContribution {\n  __isMonetaryContribution: __typename\n  __typename\n  datePaid\n  amountCentsPaid\n  ... on TwitchBitsContribution {\n    twitchAccount {\n      displayName\n      id\n    }\n  }\n  ... on TwitchSubscriptionContribution {\n    tier\n    beneficiaryTwitchAccount {\n      id\n      displayName\n    }\n    payeeTwitchAccount {\n      id\n      displayName\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '430f24fd8d38a725b4729d73a0f56528';
export default node;
