/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WingTier = "TIER_1" | "TIER_2" | "TIER_3" | "%future added value";
export type WingNight_wingNightSubscriptionVariables = {};
export type WingNight_wingNightSubscriptionResponse = {
    readonly wingNightUpdate: {
        readonly id: string;
        readonly totalContributedCents: number;
        readonly totalUsedCents: number;
        readonly tiers: ReadonlyArray<{
            readonly tier: WingTier;
            readonly " $fragmentRefs": FragmentRefs<"WingNightMenuTierFragment">;
        }>;
    };
};
export type WingNight_wingNightSubscription = {
    readonly response: WingNight_wingNightSubscriptionResponse;
    readonly variables: WingNight_wingNightSubscriptionVariables;
};



/*
subscription WingNight_wingNightSubscription {
  wingNightUpdate {
    id
    totalContributedCents
    totalUsedCents
    tiers {
      tier
      ...WingNightMenuTierFragment
    }
  }
}

fragment WingNightMenuTierFragment on WingNightTier {
  bought
  eaten
  sauce {
    name
  }
  tier
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalContributedCents",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalUsedCents",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tier",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WingNight_wingNightSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WingNight",
        "kind": "LinkedField",
        "name": "wingNightUpdate",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WingNightTier",
            "kind": "LinkedField",
            "name": "tiers",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WingNightMenuTierFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WingNight_wingNightSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WingNight",
        "kind": "LinkedField",
        "name": "wingNightUpdate",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WingNightTier",
            "kind": "LinkedField",
            "name": "tiers",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bought",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eaten",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WingSauce",
                "kind": "LinkedField",
                "name": "sauce",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66190d07adc574bc0097130a005c03c0",
    "id": null,
    "metadata": {},
    "name": "WingNight_wingNightSubscription",
    "operationKind": "subscription",
    "text": "subscription WingNight_wingNightSubscription {\n  wingNightUpdate {\n    id\n    totalContributedCents\n    totalUsedCents\n    tiers {\n      tier\n      ...WingNightMenuTierFragment\n    }\n  }\n}\n\nfragment WingNightMenuTierFragment on WingNightTier {\n  bought\n  eaten\n  sauce {\n    name\n  }\n  tier\n}\n"
  }
};
})();
(node as any).hash = '6f9987b05d589d4cee35e64d8e28f616';
export default node;
