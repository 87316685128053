import graphql from 'babel-plugin-relay/macro';
import { useFragment } from "react-relay";
import { currencyFormatter } from '../../../helpers/currency';
import { WingNightContributionFragment, WingNightContributionFragment$key } from "./__generated__/WingNightContributionFragment.graphql";

const ContributionFragment = graphql`
  fragment WingNightContributionFragment on MonetaryContribution {
    __typename
    datePaid
    amountCentsPaid
    ... on TwitchBitsContribution {
      twitchAccount {
        displayName
      }
    }
    ... on TwitchSubscriptionContribution {
      tier
      beneficiaryTwitchAccount {
        id
        displayName
      }
      payeeTwitchAccount {
        id
        displayName
      }
    }
  }
`;

const formatDescription = (contribution: WingNightContributionFragment) => {
  switch (contribution.__typename) {
    case 'TwitchBitsContribution': {
      if (contribution.twitchAccount) {
        return `${contribution.twitchAccount.displayName} donated bits`;
      }
      return `An Anonymous Gifter donated bits.`;
    }
    case 'TwitchSubscriptionContribution': {
      if (contribution.payeeTwitchAccount?.id === contribution.beneficiaryTwitchAccount!.id) {
        return `${contribution.beneficiaryTwitchAccount?.displayName} subbed at ${contribution.tier}`;
      }
      if (contribution.payeeTwitchAccount) {
        return `${contribution.payeeTwitchAccount.displayName} gifted ${contribution.beneficiaryTwitchAccount?.displayName} a ${contribution.tier} sub.`;
      }
      return `An Anonymous Gifter gifted ${contribution.beneficiaryTwitchAccount?.displayName} a ${contribution.tier} sub.`
    }
    case 'StreamLabsContribution':
      return `Someone donated via StreamLabs.`;
    default:
      return 'Unknown';
  };
};

type Props = {
  contribution: WingNightContributionFragment$key,
};

export default function WingNightContribution({ contribution }: Props) {
  const data = useFragment<WingNightContributionFragment$key>(ContributionFragment, contribution);
  return (
    <tr>
      <td>
        {new Date(data.datePaid).toLocaleString()}
      </td>
      <td>
        {currencyFormatter.format(data.amountCentsPaid / 100)}
      </td>
      <td>
        {formatDescription(data)}
      </td>
    </tr>
  );
}
