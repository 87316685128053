import { useAuth0 } from "@auth0/auth0-react";
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from "react-relay";
import { Link } from "react-router-dom";
import { Permission, RequireAuthentication_PermissionsQuery } from "./__generated__/RequireAuthentication_PermissionsQuery.graphql";

const permissionQuery = graphql`
  query RequireAuthentication_PermissionsQuery {
    me {
      permissions
    }
  }
`;

interface SpecificPermissionRequiredProps {
  children: React.ReactChild,
  permission: Permission,
}

function SpecificPermissionRequired({ children, permission }: SpecificPermissionRequiredProps) {
  let result = useLazyLoadQuery<RequireAuthentication_PermissionsQuery>(permissionQuery, {});

  if (!result.me.permissions.includes(permission)) {
    return <>You do not have the required permissions to access this page.</>;
  }

  return <>
    {children}
  </>;
}

interface Props {
  children: React.ReactChild,
  permission?: Permission,
}

export default function RequireAuthentication({ children, permission }: Props) {
  const {
    isLoading,
    isAuthenticated,
  } = useAuth0();

  if (isLoading) {
    return <></>;
  }

  if (!isAuthenticated) {
    return <>
      You must be logged in to see this page.
      <br />
      <Link to='/login'>Login</Link>
    </>;
  }

  if (permission) {
    return <SpecificPermissionRequired permission={permission}>
      {children}
    </SpecificPermissionRequired>;
  }

  return <>{children}</>;
}
