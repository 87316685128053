import React, { useEffect } from 'react';

export default function useOnScreenAction(ref: React.MutableRefObject<Element | undefined>, fn: IntersectionObserverCallback) {
  useEffect(() => {
    if (!ref) return;
    const current = ref.current!;
    const observer = new IntersectionObserver(fn)
    observer.observe(current);
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [ref, fn])
}
