/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EpisodeState = "EDITING" | "EDITING_REVIEW" | "FINAL_REVIEW" | "FOOTAGE_DUMPED" | "PENDING_METADATA" | "PUBLISHED" | "RENDERING" | "RENDER_ERROR" | "SCHEDULED" | "%future added value";
export type EpisodeLink_Episode = {
    readonly id: string;
    readonly title: string;
    readonly state: EpisodeState;
    readonly datePublished: string | null;
    readonly seasonPlacement: {
        readonly season: {
            readonly number: number;
        };
        readonly number: number;
    };
    readonly videos: ReadonlyArray<{
        readonly __typename: "YouTubeVideo";
        readonly videoId: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $refType": "EpisodeLink_Episode";
};
export type EpisodeLink_Episode$data = EpisodeLink_Episode;
export type EpisodeLink_Episode$key = {
    readonly " $data"?: EpisodeLink_Episode$data;
    readonly " $fragmentRefs": FragmentRefs<"EpisodeLink_Episode">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EpisodeLink_Episode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datePublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SeasonPlacement",
      "kind": "LinkedField",
      "name": "seasonPlacement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Season",
          "kind": "LinkedField",
          "name": "season",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "videos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoId",
              "storageKey": null
            }
          ],
          "type": "YouTubeVideo",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Episode",
  "abstractKey": null
};
})();
(node as any).hash = '077e9c627cc555c32c7706e632439ea5';
export default node;
