import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { IntroAdmin_submittedIntrosQuery } from './__generated__/IntroAdmin_submittedIntrosQuery.graphql';

const IntrosQuery = graphql`
  query IntroAdmin_submittedIntrosQuery {
    intros(
      filter: {
        state: SUBMITTED
      }
      order: {
        by: SUBMITTED_AT
        order: ASCENDING
      }
    ) {
      downloadUrl
      id
      name
      state
      submittedAt
      uploader {
        id
        name
      }
    }
  }
`;

export default function IntroAdmin() {
  const { intros } = useLazyLoadQuery<IntroAdmin_submittedIntrosQuery>(IntrosQuery, {});

  return (
    <>
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Uploader</th>
            <th>Name</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {
            intros.map((intro) => (
              <tr key={intro.id}>
                {/* submittedAt is guaranteed non-null when in `SUBMITTED` state */}
                <td>{new Date(intro.submittedAt!).toLocaleString()}</td>
                <td>{intro.uploader.name}</td>
                <td>{intro.name}</td>
                <td><a target='_blank' rel='noreferrer' href={intro.downloadUrl}>Download</a></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
}
