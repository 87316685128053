import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import AllEpisodesSeason from './Season';
import { AllEpisodes_seasonPlacementsQuery } from './__generated__/AllEpisodes_seasonPlacementsQuery.graphql';

const SeasonPlacements = graphql`
  query AllEpisodes_seasonPlacementsQuery {
    seasons {
      number
    }
  }
`;

function AllEpisodes() {
  const data = useLazyLoadQuery<AllEpisodes_seasonPlacementsQuery>(SeasonPlacements, {});
  return (
    <>
      {
        data.seasons.slice().reverse().map((season, i) => <React.Fragment key={season.number}>
          <AllEpisodesSeason number={season.number}/>
          { i < data.seasons.length - 1 && <hr /> }
        </React.Fragment>)
      }
    </>
  )
}

export default AllEpisodes;
