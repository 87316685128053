import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const auth0Connections = {
  DISCORD: 'discord',
  PATREON: 'patreon',
  TWITCH: 'twitch',
  USERNAME_PASSWORD: 'Username-Password-Authentication',
}

type LoginProps = {
  connection: string,
}

export function Login({ connection }: LoginProps) {
  const {
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      connection,
    });
  }, [loginWithRedirect, connection]);

  return <div>Redirecting...</div>;
}

export function Logout() {
  const {
    logout,
  } = useAuth0();

  useEffect(() => {
    logout()
  }, [logout]);

  return <div>Logging out...</div>
}
