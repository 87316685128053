import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { TwitchInfoFragment$key } from './__generated__/TwitchInfoFragment.graphql';
import { Typography } from '@rmwc/typography';
import { TwitchInfo_LinkAccountMutation } from './__generated__/TwitchInfo_LinkAccountMutation.graphql';
import useLinkAccount from '../../hooks/useLinkAccount';
import { auth0Connections } from '../Auth0Redirects';

const LinkAccountMutation = graphql`
  mutation TwitchInfo_LinkAccountMutation($secondaryAccountJwt: String!) {
    linkAccount(secondaryAccountJwt: $secondaryAccountJwt) {
      twitchAccount {
        ...TwitchInfoFragment
      }
    }
  }
`;

const TwitchInfoFragment = graphql`
  fragment TwitchInfoFragment on TwitchAccount {
    displayName
  }
`;

type Props = {
  twitch: TwitchInfoFragment$key | null;
}

export default function TwitchInfo(props: Props) {
  const twitchAccount = useFragment<TwitchInfoFragment$key>(TwitchInfoFragment, props.twitch);
  const {
    error,
    isLoading,
    linkAccount,
  } = useLinkAccount<TwitchInfo_LinkAccountMutation>(auth0Connections.TWITCH, LinkAccountMutation);

  return <div>
    <Typography use='headline4' tag='h4'>Twitch Account</Typography>
    {
      twitchAccount ? (
        <div>
          Linked With Twitch Account&nbsp;
          <a href={`https://twitch.tv/${twitchAccount.displayName}`}>
            {twitchAccount.displayName}
          </a>
        </div>
      ) : <>
        {
          error ? <>An error occurred! {error.message}</> : <>
            {
              isLoading ? <>Loading...</> :
              <button onClick={linkAccount}>Link your Twitch account today!</button>
            }
          </>
        }
      </>
    }
  </div>;
}
