import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { createBrowserHistory } from 'history';
import './index.css';
import Pages from './pages';
import Relay from './relay';
import reportWebVitals from './reportWebVitals';
import Nav from './pages/Nav';
import Loading from './pages/Loading';
import '@rmwc/theme/styles';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import Overlays from './overlays';
import Snackbar from './component/Snackbar';
import YouTubeCommentSnackbarHandler from './component/YouTubeCommentSnackbarHandler';
import EpisodePublishedSnackbarHandler from './component/EpisodePublishedSnackbarHandler';

const history = createBrowserHistory();

const onRedirectCallback = (appState: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
      cacheLocation='localstorage'
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      <Relay>
        <Router history={history}>
          <Switch>
            <Route path='/overlays'>
              <Suspense fallback={''}>
                <Overlays />
              </Suspense>
            </Route>
            <Route>
              <Snackbar />
              <EpisodePublishedSnackbarHandler />
              <YouTubeCommentSnackbarHandler />
              <Nav />
              <Suspense fallback={<Loading />} >
                <div style={{
                  color: 'var(--mdc-theme-on-surface)',
                  maxWidth: '1000px',
                  margin: '0 auto',
                  width: '100%',
                }}>
                  <Pages />
                </div>
              </Suspense>
            </Route>
          </Switch>
        </Router>
      </Relay>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
