import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { PatreonInfoFragment$key } from './__generated__/PatreonInfoFragment.graphql';
import { Typography } from '@rmwc/typography';
import { PatreonInfo_LinkAccountMutation } from './__generated__/PatreonInfo_LinkAccountMutation.graphql';
import useLinkAccount from '../../hooks/useLinkAccount';
import { auth0Connections } from '../Auth0Redirects';

const LinkAccountMutation = graphql`
  mutation PatreonInfo_LinkAccountMutation($secondaryAccountJwt: String!) {
    linkAccount(secondaryAccountJwt: $secondaryAccountJwt) {
      patreonAccount {
        ...PatreonInfoFragment
      }
    }
  }
`;

const PatreonInfoFragment = graphql`
  fragment PatreonInfoFragment on PatreonAccount {
    accountId
  }
`;

type Props = {
  patreon: PatreonInfoFragment$key | null;
}

export default function PatreonInfo(props: Props) {
  const patreonAccount = useFragment<PatreonInfoFragment$key>(PatreonInfoFragment, props.patreon);
  const {
    error,
    isLoading,
    linkAccount,
  } = useLinkAccount<PatreonInfo_LinkAccountMutation>(auth0Connections.PATREON, LinkAccountMutation);

  return <div>
    <Typography use='headline4' tag='h4'>Patreon Account</Typography>
    {
      patreonAccount ? (
        <div>
          Linked With Patreon Account {patreonAccount.accountId}
        </div>
      ) : <>
        {
          error ? <>An error occurred! {error.message}</> : <>
            {
              isLoading ? <>Loading...</> :
              <button onClick={linkAccount}>Link your patreon account today!</button>
            }
          </>
        }
      </>
    }
  </div>;
}
