import { Auth0Client } from '@auth0/auth0-spa-js';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
import { GraphQLTaggedNode, MutationParameters } from 'relay-runtime';

const AUTH0_LINK_ACCOUNT_TIMEOUT_SECONDS = 10 * 60;

interface MutationVariables extends MutationParameters {
  readonly variables: {
    secondaryAccountJwt: string,
  },
};

export default function useLinkAccount<T extends MutationVariables>(
  connection: string,
  mutation: GraphQLTaggedNode,
) {
  const [commitLink, isInFlight] = useMutation<T>(mutation);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const linkAccount = useCallback(() => {
    ;(async () => {
      setLoading(true);
      const tempClient = new Auth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN!,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      });

      await tempClient.loginWithPopup({
        connection,
      }, {
        timeoutInSeconds: AUTH0_LINK_ACCOUNT_TIMEOUT_SECONDS,
      });

      const subToken = await tempClient.getTokenSilently();

      commitLink({
        variables: {
          secondaryAccountJwt: subToken,
        },
        onCompleted() {
          setLoading(false);
        },
        onError: setError,
      })
    })().catch(setError);

  }, [connection, commitLink, setError, setLoading]);

  return {
    error,
    isLoading: isLoading || isInFlight,
    linkAccount,
  };
}
