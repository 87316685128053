import { Switch, Route } from 'react-router-dom';
import RequireAuthentication from '../../component/RequireAuthentication';
import IntroAdmin from './IntroAdmin';
import WingNightAdmin from './WingNightAdmin';

function Admin() {
  return (
    <Switch>
      <Route path='/admin/wing-night/'>
        <RequireAuthentication permission='MANAGE_WINGNIGHT'>
          <WingNightAdmin />
        </RequireAuthentication>
      </Route>
      <Route path='/admin/intros/'>
        <RequireAuthentication permission='READ_INTROS'>
          <IntroAdmin />
        </RequireAuthentication>
      </Route>
    </Switch>
  );
}

export default Admin;
