/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TwitchInfoFragment = {
    readonly displayName: string;
    readonly " $refType": "TwitchInfoFragment";
};
export type TwitchInfoFragment$data = TwitchInfoFragment;
export type TwitchInfoFragment$key = {
    readonly " $data"?: TwitchInfoFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"TwitchInfoFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TwitchInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "TwitchAccount",
  "abstractKey": null
};
(node as any).hash = 'e5819def60dd695cce07df984ff0ca18';
export default node;
