import graphql from 'babel-plugin-relay/macro';
import { Typography } from "@rmwc/typography";
import { MyIntrosQuery } from './__generated__/MyIntrosQuery.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { List } from '@rmwc/list';
import '@rmwc/list/styles';
import IntroTab from './MyIntrosTab';
import IntroUploader from './IntroUploader';

const MyIntrosQueryNode = graphql`
  query MyIntrosQuery {
    me {
      id
      intros {
        id
        ...MyIntrosTab_IntroBoxFragment
      }
    }
  }
`;

export default function MyIntros() {
  const { me } = useLazyLoadQuery<MyIntrosQuery>(MyIntrosQueryNode, {});

  return <>
    <Typography use='headline3' tag='h3'>Your Intros</Typography>
    <List>
      <IntroUploader />
      {
        me.intros.map((intro) => (
          <IntroTab
            key={intro.id}
            intro={intro}
          />
        ))
      }
    </List>
    <Typography use='headline5' tag='h5'>Missing Intros?</Typography>
    <p>
      Intros submitted prior to November 2021 used a different system, and as such we may not have data on them at the
      moment. We are working toward linking intros with their correct owners.
    </p>
  </>
}
