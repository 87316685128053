/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MetricBox_Metric = {
    readonly name: string;
    readonly data?: ReadonlyArray<{
        readonly dateMeasured: string;
        readonly value?: number;
        readonly unit?: {
            readonly name: string;
        };
    }>;
    readonly " $refType": "MetricBox_Metric";
};
export type MetricBox_Metric$data = MetricBox_Metric;
export type MetricBox_Metric$key = {
    readonly " $data"?: MetricBox_Metric$data;
    readonly " $fragmentRefs": FragmentRefs<"MetricBox_Metric">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricBox_Metric",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "limit",
              "value": 1
            },
            {
              "kind": "Literal",
              "name": "order",
              "value": "DESCENDING"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateMeasured",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SIDerrivedUnit",
                  "kind": "LinkedField",
                  "name": "unit",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "ScalarMeasurement",
              "abstractKey": null
            }
          ],
          "storageKey": "data(limit:1,order:\"DESCENDING\")"
        }
      ],
      "type": "TimeSeriesMetric",
      "abstractKey": null
    }
  ],
  "type": "Metric",
  "abstractKey": "__isMetric"
};
})();
(node as any).hash = '39613a4ecfd323d05da4b1db96726e69';
export default node;
