import { Typography } from '@rmwc/typography';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import DiscordInfo from './DiscordInfo';
import PatreonInfo from './PatreonInfo';
import TwitchInfo from './TwitchInfo';
import { Me_MeQuery } from './__generated__/Me_MeQuery.graphql';

const MeQuery = graphql`
  query Me_MeQuery {
    me {
      name
      discordAccount {
        ... DiscordInfoFragment
      }
      patreonAccount {
        ... PatreonInfoFragment
      }
      twitchAccount {
        ... TwitchInfoFragment
      }
    }
  }
`;

export default function Me() {
  const data = useLazyLoadQuery<Me_MeQuery>(MeQuery, {});
  return <>
    <Typography use='headline2'>{data.me.name}</Typography>
    <PatreonInfo patreon={data.me.patreonAccount} />
    <hr />
    <DiscordInfo discord={data.me.discordAccount} />
    <hr />
    <TwitchInfo twitch={data.me.twitchAccount} />
  </>;
}
