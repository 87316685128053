import { Card, CardPrimaryAction } from '@rmwc/card';
import { GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from "react-relay"
import { MetricBox_Metric$key } from './__generated__/MetricBox_Metric.graphql';

const MetricBoxFragment = graphql`
  fragment MetricBox_Metric on Metric {
    name
    ... on TimeSeriesMetric {
      data(order: DESCENDING, limit: 1) {
        dateMeasured
        ... on ScalarMeasurement {
          value
          unit {
            name
          }
        }
      }
    }
  }
`;

type Props = {
  metric: MetricBox_Metric$key,
}

function MetricBox(props: Props) {
  const metric = useFragment<MetricBox_Metric$key>(MetricBoxFragment, props.metric);

  return (
    <GridCell span={4}>
      <Card>
        <CardPrimaryAction>
          <div style={{margin: '0 1rem 1rem'}}>
            <Typography use='headline6' tag='h2'>{metric.name}</Typography>
            {
              metric.data?.[0]?.unit && <>
                {metric.data[0].value} {metric.data[0].unit.name}
              </>
            }
          </div>
        </CardPrimaryAction>
      </Card>
    </GridCell>
  )
}

export default MetricBox;
