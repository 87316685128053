/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EpisodeState = "EDITING" | "EDITING_REVIEW" | "FINAL_REVIEW" | "FOOTAGE_DUMPED" | "PENDING_METADATA" | "PUBLISHED" | "RENDERING" | "RENDER_ERROR" | "SCHEDULED" | "%future added value";
export type EpisodePublishedSnackbarHandlerSubscriptionVariables = {};
export type EpisodePublishedSnackbarHandlerSubscriptionResponse = {
    readonly episodeUpdate: {
        readonly episode: {
            readonly id: string;
            readonly title: string;
            readonly state: EpisodeState;
            readonly datePublished: string | null;
            readonly videos: ReadonlyArray<{
                readonly __typename: "YouTubeVideo";
                readonly videoId: string;
                readonly title: string;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            }>;
            readonly seasonPlacement: {
                readonly season: {
                    readonly number: number;
                    readonly placements: ReadonlyArray<{
                        readonly number: number;
                        readonly episode: {
                            readonly id: string;
                        };
                    }>;
                };
            };
        };
    };
};
export type EpisodePublishedSnackbarHandlerSubscription = {
    readonly response: EpisodePublishedSnackbarHandlerSubscriptionResponse;
    readonly variables: EpisodePublishedSnackbarHandlerSubscriptionVariables;
};



/*
subscription EpisodePublishedSnackbarHandlerSubscription {
  episodeUpdate {
    episode {
      id
      title
      state
      datePublished
      videos {
        __typename
        ... on YouTubeVideo {
          videoId
          title
        }
      }
      seasonPlacement {
        season {
          number
          placements {
            number
            episode {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EpisodeUpdateEvent",
    "kind": "LinkedField",
    "name": "episodeUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Episode",
        "kind": "LinkedField",
        "name": "episode",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datePublished",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "videos",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "videoId",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "type": "YouTubeVideo",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SeasonPlacement",
            "kind": "LinkedField",
            "name": "seasonPlacement",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Season",
                "kind": "LinkedField",
                "name": "season",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SeasonPlacement",
                    "kind": "LinkedField",
                    "name": "placements",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Episode",
                        "kind": "LinkedField",
                        "name": "episode",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EpisodePublishedSnackbarHandlerSubscription",
    "selections": (v3/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EpisodePublishedSnackbarHandlerSubscription",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "39186af4b7e2b639e075ef87464599b8",
    "id": null,
    "metadata": {},
    "name": "EpisodePublishedSnackbarHandlerSubscription",
    "operationKind": "subscription",
    "text": "subscription EpisodePublishedSnackbarHandlerSubscription {\n  episodeUpdate {\n    episode {\n      id\n      title\n      state\n      datePublished\n      videos {\n        __typename\n        ... on YouTubeVideo {\n          videoId\n          title\n        }\n      }\n      seasonPlacement {\n        season {\n          number\n          placements {\n            number\n            episode {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '262e31832671ea03ac5bc92d9568b2df';
export default node;
