import graphql from 'babel-plugin-relay/macro';
import React, { Suspense, useRef } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import EpisodeLink from "../../../component/EpisodeLink";
import { Season_seasonPlacementsQuery } from './__generated__/Season_seasonPlacementsQuery.graphql';
import '@rmwc/grid/styles';
import { Grid, GridCell } from '@rmwc/grid';
import { Card, CardMedia, CardPrimaryAction } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import useOnScreenAction from '../../../hooks/useOnScreenAction';

const SeasonPlacements = graphql`
  query Season_seasonPlacementsQuery($number: Int!) {
    season(number: $number) {
      number
      placements {
        number
        episode {
          ...EpisodeLink_Episode
        }
      }
    }
  }
`;

type Props = {
  number: number
}

function Fallback() {
  return <>
    {
      Array.from({ length: 18 }).map((_, i) => <React.Fragment key={i}>
        <GridCell span={4}>
          <Card style={{height: '100%'}}>
            <CardPrimaryAction>
              <CardMedia
                sixteenByNine
              />
              <div style={{ padding: '0 1rem 1rem' }}>
                <Typography use='headline6' tag='h2'>????????????</Typography>
                <Typography use='subtitle2' tag='h3' style={{ marginTop: '-1rem' }}>
                  Season ? &gt;
                  Episode ?
                </Typography>
              </div>
            </CardPrimaryAction>
          </Card>
        </GridCell>
      </React.Fragment>)
    }
  </>
}

type SeasonCardProps = {
  query: PreloadedQuery<Season_seasonPlacementsQuery>,
}

function SeasonCards(props: SeasonCardProps) {
  const data = usePreloadedQuery(SeasonPlacements, props.query);

  return <>
    {
      data?.season?.placements.slice().reverse().map((placement) =>
        <EpisodeLink key={placement.number} episode={placement.episode} textAlign='left' />
      )
    }
  </>;
}

function AllEpisodesSeason(props: Props) {
  const [
    queryRef,
    loadQuery,
  ] = useQueryLoader<Season_seasonPlacementsQuery>(
    SeasonPlacements,
    null,
  );
  const ref = useRef<Element>();
  useOnScreenAction(ref, ([event]) => {
    if (!event.isIntersecting || queryRef) {
      return;
    }
    loadQuery({ number: props.number });
  });

  return <>
    <Typography ref={ref} use='headline7' tag='h1' style={{ marginBottom: '0', paddingLeft: 'var(--mdc-layout-grid-margin-desktop, 24px)' }}>Season {props.number}</Typography>
    <Grid>
      <Suspense fallback={<Fallback />}>
        {queryRef ? <SeasonCards query={queryRef} /> : <Fallback />}
      </Suspense>
    </Grid>
  </>;
}

export default AllEpisodesSeason;
