import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useSubscription } from 'react-relay';

import { useMemo } from 'react';
import { WingNightAdmin_activeWingNightQuery } from './__generated__/WingNightAdmin_activeWingNightQuery.graphql';
import { WingNightAdmin_wingEatMutation } from './__generated__/WingNightAdmin_wingEatMutation.graphql';
import { WingNightAdmin_wingAddMutation } from './__generated__/WingNightAdmin_wingAddMutation.graphql';
import WingNightContribution from './WingNightContribution';
import { currencyFormatter } from '../../../helpers/currency';

const WingNightQuery = graphql`
  query WingNightAdmin_activeWingNightQuery {
    activeWingNight {
      id
      contributions {
        datePaid
        amountCentsPaid
        ... WingNightContributionFragment
      }
      totalContributedCents
      totalUsedCents
      tiers {
        bought
        eaten
        sauce {
          name
        }
        tier
      }
    }
  }
`;

const WingNightSubscription = graphql`
  subscription WingNightAdmin_wingNightSubscription {
    wingNightUpdate {
      id
      contributions {
        datePaid
        amountCentsPaid
        ... WingNightContributionFragment
      }
      totalContributedCents
      totalUsedCents
      tiers {
        bought
        eaten
        sauce {
          name
        }
        tier
      }
    }
  }
`;

const WingEat = graphql`
  mutation WingNightAdmin_wingEatMutation($wingNightId: ID!, $tier: WingTier!) {
    wingNightEatWing(id: $wingNightId, tier: $tier) {
      id
      contributions {
        datePaid
        amountCentsPaid
        ... WingNightContributionFragment
      }
      totalContributedCents
      totalUsedCents
      tiers {
        bought
        eaten
        sauce {
          name
        }
        tier
      }
    }
  }
`;

const WingAdd = graphql`
  mutation WingNightAdmin_wingAddMutation($wingNightId: ID!, $tier: WingTier!) {
    wingNightAddWing(id: $wingNightId, tier: $tier) {
      id
      contributions {
        datePaid
        amountCentsPaid
        ... WingNightContributionFragment
      }
      totalContributedCents
      totalUsedCents
      tiers {
        bought
        eaten
        sauce {
          name
        }
        tier
      }
    }
  }
`;

export default function WingNightAdmin() {
  const { activeWingNight } = useLazyLoadQuery<WingNightAdmin_activeWingNightQuery>(WingNightQuery, {});
  const [eatAWing] = useMutation<WingNightAdmin_wingEatMutation>(WingEat);
  const [addAWing] = useMutation<WingNightAdmin_wingAddMutation>(WingAdd);
  const config = useMemo(() => ({
    subscription: WingNightSubscription,
    variables: {},
  }), []);
  useSubscription(config);

  if (!activeWingNight) {
    return (
      <>
        No Active Wing Night
      </>
    );
  }

  return (
    <>
      It's Wing Night, bitch.

      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <td>
              Sauce
            </td>
            <td>
              Bought
            </td>
            <td>
              Eaten
            </td>
            <td>
              Amount Due
            </td>
            <td colSpan={2}>
              Actions
            </td>
          </tr>
        </thead>
        <tbody>
          {
            activeWingNight.tiers.map((tier) => (
              <tr key={tier.tier}>
                <td>
                  {tier.sauce.name}
                </td>
                <td>
                  {tier.bought}
                </td>
                <td>
                  {tier.eaten}
                </td>
                <td>
                  {tier.bought - tier.eaten}
                </td>
                <td>
                  <button
                    type='button'
                    onClick={() => eatAWing({
                      variables: {
                        wingNightId: activeWingNight.id,
                        tier: tier.tier,
                      }
                    })}
                  >
                    Eat
                  </button>
                </td>
                <td>
                  -----------------
                  <button
                    type='button'
                    onClick={() => addAWing({
                      variables: {
                        wingNightId: activeWingNight.id,
                        tier: tier.tier,
                      }
                    })}
                  >
                    Add an additional wing. (Only for cases of emergencies.)
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <hr />
      <div>
        Total Intake: {currencyFormatter.format(activeWingNight.totalContributedCents / 100)}
      </div>
      <hr />
      <h3>Events</h3>
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            <td>
              Date
            </td>
            <td>
              Amount
            </td>
            <td>
              Description
            </td>
          </tr>
        </thead>
        <tbody>
          {
            activeWingNight.contributions.map((contribution) => (
              <WingNightContribution
                key={contribution.datePaid}
                contribution={contribution}
              />
            ))
          }
        </tbody>
      </table>
    </>
  )
}
