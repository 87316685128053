/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DiscordInfoFragment = {
    readonly id: string;
    readonly " $refType": "DiscordInfoFragment";
};
export type DiscordInfoFragment$data = DiscordInfoFragment;
export type DiscordInfoFragment$key = {
    readonly " $data"?: DiscordInfoFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscordInfoFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscordInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscordAccount",
  "abstractKey": null
};
(node as any).hash = '91993a81f0b51f9026f57b93e5717695';
export default node;
