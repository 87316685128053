import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';

function Loading() {
  return <div style={{margin: 'auto'}}>
    <CircularProgress size={200} theme='secondary' />
  </div>
}

export default Loading;
