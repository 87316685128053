import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { EpisodeLink_Episode$key } from './__generated__/EpisodeLink_Episode.graphql';
import '@rmwc/card/styles';
import { Card, CardPrimaryAction, CardMedia, CardMediaContent } from '@rmwc/card';
import '@rmwc/typography/styles';
import { Typography } from '@rmwc/typography';
import { GridCell } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';
const EpisodeLinkFragment = graphql`
  fragment EpisodeLink_Episode on Episode {
    id
    title
    state
    datePublished
    seasonPlacement {
      season {
        number
      }
      number
    }
    videos {
      __typename
      ... on YouTubeVideo {
        videoId
      }
    }
  }
`;

enum EpisodeState {
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

type Props = {
  episode: EpisodeLink_Episode$key,
  textAlign: 'left' | 'center' | 'right',
}

function EpisodeLink(props: Props) {
  const episode = useFragment<EpisodeLink_Episode$key>(EpisodeLinkFragment, props.episode);
  const youtubeVideo = episode.videos.find(({ __typename }) => __typename === 'YouTubeVideo');
  const youtubeId: string | undefined = youtubeVideo && (youtubeVideo as any).videoId;


  return (
    <GridCell span={4}>
      <Card style={{height: '100%'}}>
        <CardPrimaryAction tag={Link} to={`/episodes/${episode.id}`}>
          <CardMedia
            sixteenByNine
            style={{
              backgroundImage: `url(https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg)`,
            }}
          >
          {episode.state !== EpisodeState.PUBLISHED && (
              <CardMediaContent>
                <Typography
                  use="subtitle2"
                  tag="div"
                  theme="textPrimaryOnDark"
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundImage:
                      'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%)',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    position: 'absolute'
                  }}
                >
                  <Icon
                    icon={episode.state === EpisodeState.SCHEDULED ? 'schedule' : 'construction'}
                    style={{
                      verticalAlign: 'bottom',
                      paddingRight: '10px'
                    }}
                  />
                  {episode.state === EpisodeState.SCHEDULED ? new Date(episode.datePublished!).toLocaleString() : episode.state}
                </Typography>
              </CardMediaContent>
            )}
          </CardMedia>
          <div style={{ padding: '0 1rem 1rem' }}>
            <Typography use='headline6' tag='h2'>{episode.title}</Typography>
            <Typography use='subtitle2' tag='h3' style={{ marginTop: '-1rem' }}>
              Season {episode.seasonPlacement.season.number}
              <Icon icon='chevron_right' style={{verticalAlign: 'bottom'}} />
              Episode {episode.seasonPlacement.number}
            </Typography>
          </div>
        </CardPrimaryAction>
      </Card>
    </GridCell>
  )
}

export default EpisodeLink;
