import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@rmwc/typography";
import LoginLink from "../../component/LoginLink";
import MyIntros from "./MyIntros";


export default function Intro() {
  const {
    isAuthenticated,
  } = useAuth0();

  return <>
    <Typography use='headline3' tag='h3'>Submit An Intro!</Typography>

    <Typography use='headline5' tag='h5'>Submission Requirements:</Typography>

    <ul>
      <li>
        User submitted intros should aim to be about 5-10 seconds in length. Longer submissions may be edited for time, or not used at all.
      </li>
      <li>
        Intros should generally follow the following format:
        <blockquote>
          "I'm [So-and-so], from [Such-and-such], and this is WhatImDoingRightNow!"
        </blockquote>
        Deviating from the format is acceptable, as long as the phrase "...this is WhatImDoingRightNow," marks the end of the intro.
      </li>
      <li>
        There are a handful of reasons your intro may not be used, including exceptionally poor sound quality, unintelligible speech, disclosing sensitive information, containing inappropriate content, etc. If your intro can't be used, you'll receive an email explaining why, so that you can remedy the situation and resubmit.
      </li>
      <li>
        And, please, for the love of all that is good and just in the world, no vertical video.
      </li>
    </ul>

    {
      !isAuthenticated ?
        <>
          <p>
            You must <LoginLink>login</LoginLink> or <LoginLink signUp>sign up</LoginLink> to upload an intro.
          </p>
        </>
      :
        <>
          <MyIntros />
        </>
    }

    <Typography use='headline5' tag='h5'>Technical Details For Technical People:</Typography>

    <ul>
      <li>
        WhatImDoingRightNow videos are played back at exactly 24 frames per second, so while intros can be submitted at any framerate, they will likely be interpolated to 24 frames per second, which can result in motion judder during smooth camera movement.
      </li>
      <li>
        Audio in intros will generally be compressed to a dynamic range spanning 18 decibels.
      </li>
      <li>
        At this time, WhatImDoingRightNow videos are not exported in HDR, so any HDR video content will be compressed to SDR.
      </li>
    </ul>
  </>
}
