import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { WingNightMenuTierFragment$key, WingTier } from './__generated__/WingNightMenuTierFragment.graphql';

const wingNightTierToPrice = {
  'TIER_1': '$4.99',
  'TIER_2': '$9.99',
  'TIER_3': '$24.99',
} as { [key in WingTier]: string };

const fragment = graphql`
  fragment WingNightMenuTierFragment on WingNightTier {
    bought
    eaten
    sauce {
      name
    }
    tier
  }
`;
type Props = {
  tier: WingNightMenuTierFragment$key
}

export default function WingNightMenuTier({ tier }: Props) {
  const wingNightTier = useFragment<WingNightMenuTierFragment$key>(fragment, tier);

  return (
    <div className='menu menu-item'>
      <span className='price'>
        {wingNightTierToPrice[wingNightTier.tier]}
      </span>
      <span className='spice'>
        {
          wingNightTier.bought !== wingNightTier.eaten ? `(${wingNightTier.bought - wingNightTier.eaten}) ` : null
        }
        {wingNightTier.sauce.name}
      </span>
      <br />
    </div>
  );
}
