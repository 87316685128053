import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import {Environment, Network, RecordSource, RequestParameters, Store, Variables, SubscribeFunction, Observable} from 'relay-runtime';
import { Network as NetworkType } from 'relay-runtime/lib/network/RelayNetworkTypes';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const fetchRelay = (auth0: Auth0ContextInterface) => async (params: RequestParameters, variables: Variables) => {
  const headers: HeadersInit = {
    'content-type': 'application/json',
  };
  if (auth0.isAuthenticated) {
    let token = null;
    try {
      token = await auth0.getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      });
    } catch(error) {
      if (error instanceof Error && error.message === 'Consent required') {
        token = await auth0.getAccessTokenWithPopup({
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        });
      } else {
        throw error;
      }
    }
    headers.authorization = `Bearer ${token}`;
  }
  const response = await fetch('/api', {
    method: 'POST',
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
    headers,
  });
  return response.json();
}

const subscriptionClient = async (auth0: Auth0ContextInterface) => {
  let authorization;
  if (auth0.isAuthenticated) {
    let token;
    try {
      token = await auth0.getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      });
    } catch(error) {
      if (error instanceof Error && error.message === 'Consent required') {
        token = await auth0.getAccessTokenWithPopup({
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        });
      } else {
        throw error;
      }
    }
    authorization = `Bearer ${token}`;
  }
  let uri = `ws${window.location.protocol === 'https:' ? 's' : ''}://${window.location.host}/api`;

  return new SubscriptionClient(uri, {
      connectionParams: {
          authorization,
      },
      reconnect: true,
  });
};

const subscribe = async (auth0: Auth0ContextInterface): Promise<SubscribeFunction> => {
  const client = await subscriptionClient(auth0);
  return (request, variables) => {
      const subscribeObservable = client.request({
          operationName: request.name,
          query: request.text || undefined,
          variables,
      });
      // This works, but the types are wrong so casting to `any`.
      return Observable.from(subscribeObservable as any);
  };
};


interface Props {
  children: any;
}


const Relay = ({ children }: Props) => {
  const auth0 = useAuth0();

  const [store] = useState(new Store(new RecordSource()));
  const [network, setNetwork] = useState<NetworkType | null>(null);
  useEffect(() => {
    (async () => {
      if (auth0.isLoading)
        return;
      setNetwork(Network.create(fetchRelay(auth0), await subscribe(auth0)));
    })();
  }, [auth0]);

  const [environment, setEnvironment] = useState<Environment | null>(null);
  useEffect(() => {
    if (!network)
      return;
    setEnvironment(new Environment({
      network,
      store,
    }));
  }, [network, store]);

  if (!environment)
    return <></>;

  if (auth0.error) {
    return <>An error occurred: {auth0.error.message}</>;
  }

  return <RelayEnvironmentProvider environment={environment}>
    {children}
  </RelayEnvironmentProvider>;
}

export default Relay;
