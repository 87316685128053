import React from 'react';
import { SnackbarQueue } from '@rmwc/snackbar';
import { queue } from './queue';

export default function Snackbar() {
  return <SnackbarQueue
    messages={queue.messages}
    leading
    stacked
  />;
}
