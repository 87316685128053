/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PatreonInfoFragment = {
    readonly accountId: string;
    readonly " $refType": "PatreonInfoFragment";
};
export type PatreonInfoFragment$data = PatreonInfoFragment;
export type PatreonInfoFragment$key = {
    readonly " $data"?: PatreonInfoFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PatreonInfoFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatreonInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    }
  ],
  "type": "PatreonAccount",
  "abstractKey": null
};
(node as any).hash = '528e7212c80f8bfdfb1684d090527875';
export default node;
