/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Me_MeQueryVariables = {};
export type Me_MeQueryResponse = {
    readonly me: {
        readonly name: string;
        readonly discordAccount: {
            readonly " $fragmentRefs": FragmentRefs<"DiscordInfoFragment">;
        } | null;
        readonly patreonAccount: {
            readonly " $fragmentRefs": FragmentRefs<"PatreonInfoFragment">;
        } | null;
        readonly twitchAccount: {
            readonly " $fragmentRefs": FragmentRefs<"TwitchInfoFragment">;
        } | null;
    };
};
export type Me_MeQuery = {
    readonly response: Me_MeQueryResponse;
    readonly variables: Me_MeQueryVariables;
};



/*
query Me_MeQuery {
  me {
    name
    discordAccount {
      ...DiscordInfoFragment
      id
    }
    patreonAccount {
      ...PatreonInfoFragment
    }
    twitchAccount {
      ...TwitchInfoFragment
      id
    }
    id
  }
}

fragment DiscordInfoFragment on DiscordAccount {
  id
}

fragment PatreonInfoFragment on PatreonAccount {
  accountId
}

fragment TwitchInfoFragment on TwitchAccount {
  displayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Me_MeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DiscordAccount",
            "kind": "LinkedField",
            "name": "discordAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DiscordInfoFragment"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PatreonAccount",
            "kind": "LinkedField",
            "name": "patreonAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PatreonInfoFragment"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TwitchAccount",
            "kind": "LinkedField",
            "name": "twitchAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TwitchInfoFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Me_MeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DiscordAccount",
            "kind": "LinkedField",
            "name": "discordAccount",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PatreonAccount",
            "kind": "LinkedField",
            "name": "patreonAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TwitchAccount",
            "kind": "LinkedField",
            "name": "twitchAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e06a5dcc09827f2e975290f65daf2cb",
    "id": null,
    "metadata": {},
    "name": "Me_MeQuery",
    "operationKind": "query",
    "text": "query Me_MeQuery {\n  me {\n    name\n    discordAccount {\n      ...DiscordInfoFragment\n      id\n    }\n    patreonAccount {\n      ...PatreonInfoFragment\n    }\n    twitchAccount {\n      ...TwitchInfoFragment\n      id\n    }\n    id\n  }\n}\n\nfragment DiscordInfoFragment on DiscordAccount {\n  id\n}\n\nfragment PatreonInfoFragment on PatreonAccount {\n  accountId\n}\n\nfragment TwitchInfoFragment on TwitchAccount {\n  displayName\n}\n"
  }
};
})();
(node as any).hash = '73289cb47b6053ce53aec67a222bf915';
export default node;
