import { Link } from 'react-router-dom';
import '@rmwc/top-app-bar/styles';
import { TopAppBar, TopAppBarFixedAdjust, TopAppBarNavigationIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@rmwc/top-app-bar';
import { Drawer, DrawerContent } from '@rmwc/drawer';
import { List, ListItem } from '@rmwc/list';
import '@rmwc/drawer/styles';
import '@rmwc/list/styles';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function Nav() {
  const { isAuthenticated } = useAuth0();
  const [drawerOpen, setDrawerState] = useState(false);
  return <>
    <TopAppBar fixed>
      <TopAppBarRow>
        <TopAppBarSection>
          <TopAppBarNavigationIcon theme='secondary' icon="menu" onClick={() => setDrawerState(!drawerOpen)}/>
          <TopAppBarTitle theme='secondary'>WIDRN</TopAppBarTitle>
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
    <TopAppBarFixedAdjust />
    <Drawer open={drawerOpen} dismissible style={{ position: 'fixed', zIndex: 100, 'left': 0, 'top': '4rem', 'bottom': 0 }}>
      <DrawerContent>
        <List>
          <ListItem tag={Link} onClick={() => setDrawerState(false)} to='/'>Home</ListItem>
          <ListItem tag={Link} onClick={() => setDrawerState(false)} to='/episodes'>Episodes</ListItem>
          <ListItem tag={Link} onClick={() => setDrawerState(false)} to='/metrics'>Metrics</ListItem>
          <ListItem tag={Link} onClick={() => setDrawerState(false)} to='/intro'>Submit An Intro</ListItem>
          {
            isAuthenticated && <>
              <ListItem tag={Link} onClick={() => setDrawerState(false)} to='/me'>Me</ListItem>
            </>
          }
        </List>
      </DrawerContent>
    </Drawer>
  </>;
}

export default Nav;
