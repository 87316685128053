/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type IntroState = "APPROVED" | "SUBMITTED" | "UPLOADED" | "UPLOADING" | "USED" | "%future added value";
export type MyIntrosTab_IntroBoxFragment = {
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly state: IntroState;
    readonly submittedAt: string | null;
    readonly uploadPercentage: number | null;
    readonly " $refType": "MyIntrosTab_IntroBoxFragment";
};
export type MyIntrosTab_IntroBoxFragment$data = MyIntrosTab_IntroBoxFragment;
export type MyIntrosTab_IntroBoxFragment$key = {
    readonly " $data"?: MyIntrosTab_IntroBoxFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MyIntrosTab_IntroBoxFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyIntrosTab_IntroBoxFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submittedAt",
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploadPercentage",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Intro",
  "abstractKey": null
};
(node as any).hash = 'b3829c576a0c2666d9ce887cfd2b2255';
export default node;
