/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EpisodeRole = "AUDIO" | "CAMERA" | "COLOR" | "EDITING" | "GRAPHICS" | "MUSIC" | "QUALITY_CONTROL" | "%future added value";
export type EpisodeRole_roleFragment = {
    readonly roles: ReadonlyArray<EpisodeRole>;
    readonly " $refType": "EpisodeRole_roleFragment";
};
export type EpisodeRole_roleFragment$data = EpisodeRole_roleFragment;
export type EpisodeRole_roleFragment$key = {
    readonly " $data"?: EpisodeRole_roleFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"EpisodeRole_roleFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EpisodeRole_roleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    }
  ],
  "type": "EpisodeCredit",
  "abstractKey": null
};
(node as any).hash = '6cc8e4744d4006a36bb75160cda877e4';
export default node;
