/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Permission = "MANAGE_CONTRIBUTIONS" | "MANAGE_EPISODES" | "MANAGE_METRICS" | "MANAGE_WINGNIGHT" | "MANAGE_YOUTUBE" | "READ_CONTRIBUTIONS" | "READ_EPISODES" | "READ_INTROS" | "READ_PERSONS" | "%future added value";
export type RequireAuthentication_PermissionsQueryVariables = {};
export type RequireAuthentication_PermissionsQueryResponse = {
    readonly me: {
        readonly permissions: ReadonlyArray<Permission>;
    };
};
export type RequireAuthentication_PermissionsQuery = {
    readonly response: RequireAuthentication_PermissionsQueryResponse;
    readonly variables: RequireAuthentication_PermissionsQueryVariables;
};



/*
query RequireAuthentication_PermissionsQuery {
  me {
    permissions
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RequireAuthentication_PermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RequireAuthentication_PermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "925790f914c09e2a05e0b4f489eb556d",
    "id": null,
    "metadata": {},
    "name": "RequireAuthentication_PermissionsQuery",
    "operationKind": "query",
    "text": "query RequireAuthentication_PermissionsQuery {\n  me {\n    permissions\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e68d2270338e33e2b1e3cbf85476a830';
export default node;
