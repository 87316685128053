/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MyIntrosQueryVariables = {};
export type MyIntrosQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly intros: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"MyIntrosTab_IntroBoxFragment">;
        }>;
    };
};
export type MyIntrosQuery = {
    readonly response: MyIntrosQueryResponse;
    readonly variables: MyIntrosQueryVariables;
};



/*
query MyIntrosQuery {
  me {
    id
    intros {
      id
      ...MyIntrosTab_IntroBoxFragment
    }
  }
}

fragment MyIntrosTab_IntroBoxFragment on Intro {
  description
  id
  name
  state
  submittedAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyIntrosQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Intro",
            "kind": "LinkedField",
            "name": "intros",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MyIntrosTab_IntroBoxFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyIntrosQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Intro",
            "kind": "LinkedField",
            "name": "intros",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uploadPercentage",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9fb876c72d003d04e61aa06b33dbc5e",
    "id": null,
    "metadata": {},
    "name": "MyIntrosQuery",
    "operationKind": "query",
    "text": "query MyIntrosQuery {\n  me {\n    id\n    intros {\n      id\n      ...MyIntrosTab_IntroBoxFragment\n    }\n  }\n}\n\nfragment MyIntrosTab_IntroBoxFragment on Intro {\n  description\n  id\n  name\n  state\n  submittedAt\n}\n"
  }
};
})();
(node as any).hash = '844eff577339ba344063c5abbe02f28b';
export default node;
