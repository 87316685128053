import graphql from 'babel-plugin-relay/macro';
import { EpisodeRole } from "./__generated__/EpisodeRole_roleFragment.graphql";

graphql`
  fragment EpisodeRole_roleFragment on EpisodeCredit {
    roles
  }
`;

export const roleFormatter = (role: EpisodeRole): string => {
  switch (role) {
    case 'AUDIO':
      return 'Audio';
    case 'CAMERA':
      return 'Camera';
    case 'COLOR':
      return 'Color';
    case 'EDITING':
      return 'Editing';
    case 'GRAPHICS':
      return 'Graphics';
    case 'MUSIC':
      return 'Music';
    case 'QUALITY_CONTROL':
        return 'Quality Control';
    default:
      // This asserts that the switch cases above are complete as of compile time.
      const _role: "%future added value" = role;
      // At run time, any new values are returned as-is
      return _role;
  }
}
