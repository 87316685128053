import { Grid } from '@rmwc/grid';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import MetricBox from '../component/MetricBox';
import { Metrics_AllMetricsQuery } from './__generated__/Metrics_AllMetricsQuery.graphql';

const AllMetricQuery = graphql`
  query Metrics_AllMetricsQuery {
    metrics {
      name
      ... MetricBox_Metric
    }
  }
`;

function Metrics() {
  const data = useLazyLoadQuery<Metrics_AllMetricsQuery>(AllMetricQuery, {});
  return <Grid>
    {
      data.metrics.map(metric => (
        <MetricBox key={metric.name} metric={metric} />
      ))
    }
  </Grid>
}

export default Metrics;
