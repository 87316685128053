import { CollapsibleList, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import '@rmwc/snackbar/styles';
import graphql from 'babel-plugin-relay/macro';
import { useCallback, useRef, useState } from "react";
import { commitLocalUpdate, useMutation, useRelayEnvironment } from 'react-relay';
import ReactS3Uploader from "react-s3-uploader";
import { IntroUploader_FinalizeMutation } from './__generated__/IntroUploader_FinalizeMutation.graphql';
import { IntroUploader_S3UrlMutation } from './__generated__/IntroUploader_S3UrlMutation.graphql';

const S3UploadUrl = graphql`
  mutation IntroUploader_S3UrlMutation($input: IntroUploadUrlInput!) {
    introUploadUrl(input: $input) {
      s3Url
      intro {
        id
        uploader {
          id
          intros {
            id
          }
        }
        # We should fetch everything needed for the introboxes too
        ...MyIntrosTab_IntroBoxFragment
      }
    }
  }
`;

const FinalizeUpload = graphql`
  mutation IntroUploader_FinalizeMutation($input: IntroUploadCompleteInput!) {
    introUploadComplete(input: $input) {
      id
      # This causes a state transition, so we should fetch that.
      state
    }
  }
`;

const formatErrorForSnackbar = (error: Error) => {
  if (Object.prototype.hasOwnProperty.call(error, 'source')) {
    // Untyped relay error
    return (error as any).source.errors[0].message;
  }
  return error.message;
}

export default function IntroUploader() {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const redirectClickToFileInput = useCallback(
    () => inputRef.current?.click?.(),
    [inputRef],
  );
  const [error, setError] = useState<Error | null>(null);
  const environment = useRelayEnvironment();
  /* This mutable state is a bit weird, but it is to work around a limitation of ReactS3Uploader:
   * When the upload is initialized, ReactS3Uploader captures the value of `setUploadPercentage`
   * so if this was some non-mutable state (i.e. useState<null | string>) the `null` value is
   * captured and not the correct `id`.
   *
   * By making it so that we mutate an object that exists already, we know that we capture the
   * object that will have the value set on it. This is basically useRef, but we need to support
   * multiple uploads, so we need to be able to set a new ref object in to the state so that both
   * the old and new values remain valid.
   */
  const [introRef, setIntroRef] = useState<{ current: null | string }>({ current: null });
  const setUploadPercentage = useCallback((percent) => {
    commitLocalUpdate(environment, store => {
      // introRef.current is non-null here due to being set in `getSignedUrl` before `cb` is called.
      store.get(introRef.current!)?.setValue(percent, 'uploadPercentage');
    })
  }, [environment, introRef]);
  const [commitS3Mutation] = useMutation<IntroUploader_S3UrlMutation>(S3UploadUrl);
  const [commitFinalizeUpload] = useMutation<IntroUploader_FinalizeMutation>(FinalizeUpload);

  const getSignedUrl = useCallback(
    (file: File, cb): void => {
      commitS3Mutation({
        variables: {
          input: {
            filename: file.name,
            mimeType: file.type,
          }
        },
        onCompleted: (data) => {
          introRef.current = data.introUploadUrl.intro.id;
          commitLocalUpdate(environment, store => {
            store.get(introRef.current!)?.setValue(0, 'uploadPercentage');
          });
          // Create a new ref for future uploads so they get new callbacks
          setIntroRef({ current: null });
          cb({
            signedUrl: data.introUploadUrl.s3Url,
            id: data.introUploadUrl.intro.id,
          });
        },
        onError: setError,
      });
    },
    [commitS3Mutation, environment, introRef, setError],
  );
  const onFinishUploading = useCallback(
    (params) => {
      commitFinalizeUpload({
        variables: {
          input: {
            id: params.id,
          }
        },
        onError: setError,
      });
    },
    [commitFinalizeUpload, setError],
  )

  return <>
    <Snackbar
      open={error !== null}
      timeout={-1}
      message={error && formatErrorForSnackbar(error)}
      action={
        <SnackbarAction
          label='Dismiss'
          onClick={() => setError(null)}
        />
      }
    />
    <CollapsibleList
      open={false}
      onClick={redirectClickToFileInput}
      style={{
        marginBottom: '20px',
      }}
      handle={
        <ListItem
          theme='primaryBg'
        >
          <ListItemText theme='onPrimary'>
            <ListItemPrimaryText>Upload a New Intro</ListItemPrimaryText>
            <ListItemSecondaryText theme='onPrimary' style={{
              textTransform: 'uppercase',
            }}>
              Click here to upload a new intro
            </ListItemSecondaryText>
          </ListItemText>
          <ListItemMeta theme='secondary' icon='add' />
        </ListItem>
      }
    >
      <ReactS3Uploader
        getSignedUrl={getSignedUrl}
        onFinish={onFinishUploading}
        onProgress={setUploadPercentage}
        uploadRequestHeaders={{}}
        inputRef={(ref) => inputRef.current = ref}
      />
    </CollapsibleList>
  </>;
}
