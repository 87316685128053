import { Typography } from '@rmwc/typography';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import EpisodeLink from '../../component/EpisodeLink';
import { Episode_episodeByIdQuery } from './__generated__/Episode_episodeByIdQuery.graphql';
import { Grid } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';
import { useMemo } from 'react';
import { roleFormatter } from '../../constants/EpisodeRole';


const EpisodeQuery = graphql`
  query Episode_episodeByIdQuery($episodeId: ID!) {
    episode(id: $episodeId) {
      id
      credits {
        appearedAs
        person {
          id
          name
        }
        roles
      }
      title
      videos {
        __typename
        ... on YouTubeVideo {
          videoId
        }
      }
      seasonPlacement {
        number
        season {
          number
        }
      }
      previousEpisode {
        ... EpisodeLink_Episode
      }
      nextEpisode {
        ... EpisodeLink_Episode
      }
    }
  }
`;

type Params = {
  episodeId: string,
};

type YouTubeVideo = {
  __typename: "YouTubeVideo"
  videoId: string
};

const isYouTubeVideo = (item: { __typename: string }): item is YouTubeVideo => {
  return item.__typename === "YouTubeVideo";
}

function Episode() {
  const { episodeId } = useParams<Params>();
  const data = useLazyLoadQuery<Episode_episodeByIdQuery>(EpisodeQuery, {
    episodeId,
  });
  const youTubeVideo = useMemo(
    () => data.episode.videos.find(isYouTubeVideo),
    [data],
  );
  return (
    <>
      <Typography use='subtitle2' tag='h3' style={{ marginBottom: '-1rem' }}>
        Season {data.episode.seasonPlacement.season.number}
        <Icon icon='chevron_right' style={{verticalAlign: 'bottom'}} />
        Episode {data.episode.seasonPlacement.number}
      </Typography>
      <Typography use='headline6' tag='h2'>{data.episode.title}</Typography>
      {
        youTubeVideo ? (
          <div style={{position: 'relative'}}>
            <div style={{paddingTop: '42.2%'}}>
              <iframe
                allowFullScreen
                frameBorder={0}
                title={data.episode.title}
                src={`//www.youtube.com/embed/${youTubeVideo.videoId}`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          </div>
        ) : 'There is no YouTube video for this episode.'
      }
      <Grid>
        {data.episode.previousEpisode && <EpisodeLink episode={data.episode.previousEpisode} textAlign='left' />}
        {data.episode.nextEpisode && <EpisodeLink episode={data.episode.nextEpisode} textAlign='right' />}
      </Grid>
      <div>
      <Typography use='headline6' tag='h4'>
        Credits
      </Typography>
        {
          data.episode.credits.length ? (
            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <td>
                    Name
                  </td>
                  <td>
                    Roles
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  data.episode.credits.map((credit) => (
                    <tr key={credit.person.id}>
                      <td>
                        {credit.person.name}
                        {
                          credit.appearedAs !== credit.person.name && ` (Credited as "${credit.appearedAs}")`
                        }
                      </td>
                      <td>
                        {
                          credit.roles.map(roleFormatter).join(', ')
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : 'There are no credits recorded for this episode at this time.'
        }
      </div>
    </>
  )
}

export default Episode;
