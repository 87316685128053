/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TwitchInfo_LinkAccountMutationVariables = {
    secondaryAccountJwt: string;
};
export type TwitchInfo_LinkAccountMutationResponse = {
    readonly linkAccount: {
        readonly twitchAccount: {
            readonly " $fragmentRefs": FragmentRefs<"TwitchInfoFragment">;
        } | null;
    };
};
export type TwitchInfo_LinkAccountMutation = {
    readonly response: TwitchInfo_LinkAccountMutationResponse;
    readonly variables: TwitchInfo_LinkAccountMutationVariables;
};



/*
mutation TwitchInfo_LinkAccountMutation(
  $secondaryAccountJwt: String!
) {
  linkAccount(secondaryAccountJwt: $secondaryAccountJwt) {
    twitchAccount {
      ...TwitchInfoFragment
      id
    }
    id
  }
}

fragment TwitchInfoFragment on TwitchAccount {
  displayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "secondaryAccountJwt"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "secondaryAccountJwt",
    "variableName": "secondaryAccountJwt"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TwitchInfo_LinkAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "linkAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TwitchAccount",
            "kind": "LinkedField",
            "name": "twitchAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TwitchInfoFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TwitchInfo_LinkAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "linkAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TwitchAccount",
            "kind": "LinkedField",
            "name": "twitchAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c978fd0759de6652fa44a7a110d5ea07",
    "id": null,
    "metadata": {},
    "name": "TwitchInfo_LinkAccountMutation",
    "operationKind": "mutation",
    "text": "mutation TwitchInfo_LinkAccountMutation(\n  $secondaryAccountJwt: String!\n) {\n  linkAccount(secondaryAccountJwt: $secondaryAccountJwt) {\n    twitchAccount {\n      ...TwitchInfoFragment\n      id\n    }\n    id\n  }\n}\n\nfragment TwitchInfoFragment on TwitchAccount {\n  displayName\n}\n"
  }
};
})();
(node as any).hash = 'c85e906a2018f5e0c7be4e576801802c';
export default node;
