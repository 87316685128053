/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type IntroState = "APPROVED" | "SUBMITTED" | "UPLOADED" | "UPLOADING" | "USED" | "%future added value";
export type IntroAdmin_submittedIntrosQueryVariables = {};
export type IntroAdmin_submittedIntrosQueryResponse = {
    readonly intros: ReadonlyArray<{
        readonly downloadUrl: string;
        readonly id: string;
        readonly name: string;
        readonly state: IntroState;
        readonly submittedAt: string | null;
        readonly uploader: {
            readonly id: string;
            readonly name: string;
        };
    }>;
};
export type IntroAdmin_submittedIntrosQuery = {
    readonly response: IntroAdmin_submittedIntrosQueryResponse;
    readonly variables: IntroAdmin_submittedIntrosQueryVariables;
};



/*
query IntroAdmin_submittedIntrosQuery {
  intros(filter: {state: SUBMITTED}, order: {by: SUBMITTED_AT, order: ASCENDING}) {
    downloadUrl
    id
    name
    state
    submittedAt
    uploader {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "filter",
        "value": {
          "state": "SUBMITTED"
        }
      },
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "by": "SUBMITTED_AT",
          "order": "ASCENDING"
        }
      }
    ],
    "concreteType": "Intro",
    "kind": "LinkedField",
    "name": "intros",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadUrl",
        "storageKey": null
      },
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submittedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "uploader",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": "intros(filter:{\"state\":\"SUBMITTED\"},order:{\"by\":\"SUBMITTED_AT\",\"order\":\"ASCENDING\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IntroAdmin_submittedIntrosQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IntroAdmin_submittedIntrosQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4319843d75ad6fa0f7a935b63609546f",
    "id": null,
    "metadata": {},
    "name": "IntroAdmin_submittedIntrosQuery",
    "operationKind": "query",
    "text": "query IntroAdmin_submittedIntrosQuery {\n  intros(filter: {state: SUBMITTED}, order: {by: SUBMITTED_AT, order: ASCENDING}) {\n    downloadUrl\n    id\n    name\n    state\n    submittedAt\n    uploader {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7a2f9fbf64025e863023ddbe43673182';
export default node;
