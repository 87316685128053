import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DiscordInfoFragment$key } from './__generated__/DiscordInfoFragment.graphql';
import { Typography } from '@rmwc/typography';
import { DiscordInfo_LinkAccountMutation } from './__generated__/DiscordInfo_LinkAccountMutation.graphql';
import useLinkAccount from '../../hooks/useLinkAccount';
import { auth0Connections } from '../Auth0Redirects';

const LinkAccountMutation = graphql`
  mutation DiscordInfo_LinkAccountMutation($secondaryAccountJwt: String!) {
    linkAccount(secondaryAccountJwt: $secondaryAccountJwt) {
      discordAccount {
        ...DiscordInfoFragment
      }
    }
  }
`;

const DiscordInfoFragment = graphql`
  fragment DiscordInfoFragment on DiscordAccount {
    id
  }
`;

type Props = {
  discord: DiscordInfoFragment$key | null;
}

export default function DiscordInfo(props: Props) {
  const discordAccount = useFragment<DiscordInfoFragment$key>(DiscordInfoFragment, props.discord);
  const {
    error,
    isLoading,
    linkAccount,
  } = useLinkAccount<DiscordInfo_LinkAccountMutation>(auth0Connections.DISCORD, LinkAccountMutation);

  return <div>
    <Typography use='headline4' tag='h4'>Discord Account</Typography>
    {
      discordAccount ? (
        <div>
          Linked With Discord Account {discordAccount.id}
        </div>
      ) : <>
        {
          error ? <>An error occurred! {error.message}</> : <>
            {
              isLoading ? <>Loading...</> :
              <button onClick={linkAccount}>Link your Discord account today!</button>
            }
          </>
        }
      </>
    }
  </div>;
}
