import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useSubscription } from 'react-relay';
import { WingNight_activeWingNightQuery } from './__generated__/WingNight_activeWingNightQuery.graphql';

import './index.css';
import WingNightMenuTier from './WingNightMenuTier';
import { useMemo } from 'react';

const WingNightQuery = graphql`
  query WingNight_activeWingNightQuery {
    activeWingNight {
      id
      totalContributedCents
      totalUsedCents
      tiers {
        tier
        ... WingNightMenuTierFragment
      }
    }
  }
`;

const WingNightSubscription = graphql`
  subscription WingNight_wingNightSubscription {
    wingNightUpdate {
      id
      totalContributedCents
      totalUsedCents
      tiers {
        tier
        ... WingNightMenuTierFragment
      }
    }
  }
`;

const WingNight = () => {
  const { activeWingNight } = useLazyLoadQuery<WingNight_activeWingNightQuery>(WingNightQuery, {});
  const config = useMemo(() => ({
    subscription: WingNightSubscription,
    variables: {},
  }), []);
  useSubscription(config);

  if (!activeWingNight) {
    return (
      <></>
    );
  }

  const bitsLeftOver = activeWingNight.totalContributedCents - activeWingNight.totalUsedCents;

  return (
    <div className='wing-night-overlay'>
      <div className='menu'>
        Wing Night!
      </div>
      <div className='menu'>
        Make me eat a wing with
        <br />
        your subs/donations/bits
      </div>
      <br />
      {
        activeWingNight.tiers.map((tier) => (
          <WingNightMenuTier
            key={tier.tier}
            tier={tier}
          />
        ))
      }
      <div className='menu bitTotal'>
        Current Bit Total:
        <div className='spice'>
          {bitsLeftOver} / 2499
          <div className='menu progressBar bitProgressBar'>
            <div className='progressBarInner' style={{
              width: `${bitsLeftOver / 2499 * 100}%`,
            }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WingNight;
