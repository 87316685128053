import { useAuth0 } from "@auth0/auth0-react"
import { useCallback } from "react";
import { auth0Connections } from "../../pages/Auth0Redirects";


type Props = {
    children: React.ReactChild,
    signUp?: boolean,
}

export default function LoginLink(props: Props) {
  const { loginWithRedirect } = useAuth0();
  const login = useCallback((event) => {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
      connection: auth0Connections.USERNAME_PASSWORD,
      screen_hint: props.signUp ? 'signup' : undefined,
    });
    event.preventDefault();
  }, [loginWithRedirect, props.signUp]);

  return (
    <a
      href={props.signUp ? '/signup' : '/login'}
      onClick={login}
    >
      {props.children}
    </a>
  );
}
