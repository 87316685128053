/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Season_seasonPlacementsQueryVariables = {
    number: number;
};
export type Season_seasonPlacementsQueryResponse = {
    readonly season: {
        readonly number: number;
        readonly placements: ReadonlyArray<{
            readonly number: number;
            readonly episode: {
                readonly " $fragmentRefs": FragmentRefs<"EpisodeLink_Episode">;
            };
        }>;
    } | null;
};
export type Season_seasonPlacementsQuery = {
    readonly response: Season_seasonPlacementsQueryResponse;
    readonly variables: Season_seasonPlacementsQueryVariables;
};



/*
query Season_seasonPlacementsQuery(
  $number: Int!
) {
  season(number: $number) {
    number
    placements {
      number
      episode {
        ...EpisodeLink_Episode
        id
      }
    }
  }
}

fragment EpisodeLink_Episode on Episode {
  id
  title
  state
  datePublished
  seasonPlacement {
    season {
      number
    }
    number
  }
  videos {
    __typename
    ... on YouTubeVideo {
      videoId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "number"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "number",
    "variableName": "number"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Season_seasonPlacementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Season",
        "kind": "LinkedField",
        "name": "season",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SeasonPlacement",
            "kind": "LinkedField",
            "name": "placements",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Episode",
                "kind": "LinkedField",
                "name": "episode",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EpisodeLink_Episode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Season_seasonPlacementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Season",
        "kind": "LinkedField",
        "name": "season",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SeasonPlacement",
            "kind": "LinkedField",
            "name": "placements",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Episode",
                "kind": "LinkedField",
                "name": "episode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datePublished",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SeasonPlacement",
                    "kind": "LinkedField",
                    "name": "seasonPlacement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Season",
                        "kind": "LinkedField",
                        "name": "season",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "videos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "videoId",
                            "storageKey": null
                          }
                        ],
                        "type": "YouTubeVideo",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42adebbff71fd2b34e649a834d63f685",
    "id": null,
    "metadata": {},
    "name": "Season_seasonPlacementsQuery",
    "operationKind": "query",
    "text": "query Season_seasonPlacementsQuery(\n  $number: Int!\n) {\n  season(number: $number) {\n    number\n    placements {\n      number\n      episode {\n        ...EpisodeLink_Episode\n        id\n      }\n    }\n  }\n}\n\nfragment EpisodeLink_Episode on Episode {\n  id\n  title\n  state\n  datePublished\n  seasonPlacement {\n    season {\n      number\n    }\n    number\n  }\n  videos {\n    __typename\n    ... on YouTubeVideo {\n      videoId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c7968910e41d93cc0a7080b80c2f58cb';
export default node;
