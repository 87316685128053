/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type IntroState = "APPROVED" | "SUBMITTED" | "UPLOADED" | "UPLOADING" | "USED" | "%future added value";
export type MyIntrosTab_IntroSubmitMutationVariables = {
    id: string;
};
export type MyIntrosTab_IntroSubmitMutationResponse = {
    readonly introSubmit: {
        readonly id: string;
        readonly name: string;
        readonly description: string;
        readonly state: IntroState;
        readonly submittedAt: string | null;
    };
};
export type MyIntrosTab_IntroSubmitMutation = {
    readonly response: MyIntrosTab_IntroSubmitMutationResponse;
    readonly variables: MyIntrosTab_IntroSubmitMutationVariables;
};



/*
mutation MyIntrosTab_IntroSubmitMutation(
  $id: ID!
) {
  introSubmit(id: $id) {
    id
    name
    description
    state
    submittedAt
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Intro",
    "kind": "LinkedField",
    "name": "introSubmit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submittedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyIntrosTab_IntroSubmitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyIntrosTab_IntroSubmitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2557e5c45f3b9131270104e4385f40ec",
    "id": null,
    "metadata": {},
    "name": "MyIntrosTab_IntroSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation MyIntrosTab_IntroSubmitMutation(\n  $id: ID!\n) {\n  introSubmit(id: $id) {\n    id\n    name\n    description\n    state\n    submittedAt\n  }\n}\n"
  }
};
})();
(node as any).hash = '7668fe3edbb6c907394ed173d3c9cc64';
export default node;
