/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WingTier = "TIER_1" | "TIER_2" | "TIER_3" | "%future added value";
export type WingNightMenuTierFragment = {
    readonly bought: number;
    readonly eaten: number;
    readonly sauce: {
        readonly name: string;
    };
    readonly tier: WingTier;
    readonly " $refType": "WingNightMenuTierFragment";
};
export type WingNightMenuTierFragment$data = WingNightMenuTierFragment;
export type WingNightMenuTierFragment$key = {
    readonly " $data"?: WingNightMenuTierFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"WingNightMenuTierFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WingNightMenuTierFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bought",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eaten",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WingSauce",
      "kind": "LinkedField",
      "name": "sauce",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tier",
      "storageKey": null
    }
  ],
  "type": "WingNightTier",
  "abstractKey": null
};
(node as any).hash = '45fe538622072e449994fc3ad2edea0e';
export default node;
