import graphql from 'babel-plugin-relay/macro';
import { useFragment, useMutation } from 'react-relay';
import { useCallback, useEffect, useState } from 'react';
import { CollapsibleList, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import '@rmwc/list/styles';
import { MyIntrosTab_IntroBoxFragment$data, MyIntrosTab_IntroBoxFragment$key } from './__generated__/MyIntrosTab_IntroBoxFragment.graphql';
import '@rmwc/textfield/styles';
import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { MyIntrosTab_IntroUpdateMutation } from './__generated__/MyIntrosTab_IntroUpdateMutation.graphql';
import { MyIntrosTab_IntroSubmitMutation } from './__generated__/MyIntrosTab_IntroSubmitMutation.graphql';

const IntroTabFragment = graphql`
  fragment MyIntrosTab_IntroBoxFragment on Intro {
    description
    id
    name
    state
    submittedAt
    uploadPercentage
  }
`;

const IntroUpdateMutation = graphql`
  mutation MyIntrosTab_IntroUpdateMutation(
    $id: ID!
    $input: IntroMetadataUpdate!
  ) {
    introMetadataUpdate(
      id: $id
      input: $input
    ) {
      id
      name
      description
    }
  }
`;

const METADATA_UPDATE_DEBOUNCE_TIME = 1000;

const IntroSubmitMutation = graphql`
  mutation MyIntrosTab_IntroSubmitMutation(
    $id: ID!
  ) {
    introSubmit(
      id: $id
    ) {
      id
      name
      description
      state
      submittedAt
    }
  }
`;

type IntroTabProps = {
  intro: MyIntrosTab_IntroBoxFragment$key,
}

const formatIntroState = (intro: MyIntrosTab_IntroBoxFragment$data): string => {
  switch (intro.state) {
    case 'UPLOADING':
      return 'Currently uploading';
    case 'UPLOADED':
      return 'Upload complete';
    case 'SUBMITTED':
      // submittedAt is only null if the Intros has yet to be submitted, so we can assert it to be non-null
      return `Submitted on ${new Date(intro.submittedAt!).toLocaleDateString()}`;
    case 'APPROVED':
      return 'Approved for use in a future episode';
    case 'USED':
      return 'Used in an episode';
    default:
      return intro.state;
  }
}

const formatButtonLabel = (isUploading: boolean, isDirty: boolean, isSubmitting: boolean) => {
  if (isUploading) {
    return 'Uploading';
  }
  if (isDirty) {
    return 'Saving';
  }
  if (isSubmitting) {
    return 'Submitting';
  }
  return 'Submit';
}

export default function IntroTab(props: IntroTabProps) {
  const intro = useFragment<MyIntrosTab_IntroBoxFragment$key>(IntroTabFragment, props.intro);
  const isUploading = intro.state === 'UPLOADING';
  const [isOpen, setOpen] = useState(isUploading);
  const editable = [
    'UPLOADING',
    'UPLOADED'
  ].includes(intro.state);

  const [isDirty, setDirty] = useState(false);
  const [name, setName] = useState(intro.name);
  const [description, setDescription] = useState(intro.description);

  const [commitUpdateMetadata] = useMutation<MyIntrosTab_IntroUpdateMutation>(IntroUpdateMutation);
  const updateIntroMetadata = useCallback(() => {
    commitUpdateMetadata({
      variables: {
        id: intro.id,
        input: {
          description,
          name,
        },
      },
    })
  }, [intro, name, description, commitUpdateMetadata]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [commitSubmitIntro] = useMutation<MyIntrosTab_IntroSubmitMutation>(IntroSubmitMutation);
  const submitIntro = useCallback(() => {
    setSubmitting(true);
    commitSubmitIntro({
      variables: {
        id: intro.id,
      },
      onCompleted() {
        setSubmitting(false);
      },
      optimisticResponse: {
        introSubmit: {
          ...intro,
          state: 'SUBMITTED',
          submittedAt: new Date().toUTCString(),
        },
      },
    })
  }, [intro, commitSubmitIntro, setSubmitting]);

  useEffect(() => {
    const newIsDirty = intro.name !== name || intro.description !== description;
    setDirty(newIsDirty);

    if (newIsDirty) {
      const timeoutId = setTimeout(updateIntroMetadata, METADATA_UPDATE_DEBOUNCE_TIME);
      return () => {
        if (newIsDirty) {
          clearTimeout(timeoutId)
        }
      }
    }
  }, [intro, name, description, updateIntroMetadata]);

  return <CollapsibleList
    key={intro.id}
    open={isOpen}
    handle={
      <ListItem
        theme='primaryBg'
        onClick={() => setOpen(!isOpen)}
      >
        <ListItemText theme='onPrimary'>
          <ListItemPrimaryText>{name}</ListItemPrimaryText>
          <ListItemSecondaryText theme='onPrimary' style={{
            textTransform: 'uppercase',
          }}>
            {formatIntroState(intro)}
          </ListItemSecondaryText>
        </ListItemText>
        <ListItemMeta theme='secondary' icon="chevron_right" />
      </ListItem>
    }
  >
    <div style={{
      color: 'var(--mdc-theme-on-primary)',
      backgroundColor: 'var(--mdc-theme-primary)',
      borderTop: '1px solid var(--mdc-theme-secondary)',
      borderImage: (
        isUploading && intro.uploadPercentage !== null
        ? `linear-gradient(to right, var(--mdc-theme-secondary) ${intro.uploadPercentage}%, var(--mdc-theme-primary) ${intro.uploadPercentage}%) 100% 1`
        : 'none'
      ),
      marginBottom: '20px',
      padding: '16px',
    }}>
      {
        editable && <>
          <div style={{
            alignItems: 'center',
            display: 'flex',
            margin: '-16px 0 0',
          }}>
            <Icon
              icon='warning'
              style={{
                margin: '0 8px',
              }}
            />
            <p
              style={{
                flexGrow: 1,
                margin: '16px 8px 16px 0',
              }}
            >
              The data you enter here is primarily for your use, but may be made public in the future.
              <br />
              You will not be able to edit it after submission.
            </p>
          </div>
        </>
      }
      <TextField
        style={{
          width: '100%',
        }}
        outlined
        label='Name'
        type='text'
        value={(editable || name) ? name : 'No name provided.'}
        disabled={!editable}
        onChange={({ target }) => setName((target as any).value)}
      />
      <TextField
        style={{
          marginTop: '16px',
          width: '100%',
        }}
        outlined
        textarea
        label='Description'
        type='text'
        value={(editable || description) ? description : 'No description provided.'}
        disabled={!editable}
        onChange={({ target }) => setDescription((target as any).value)}
      />
      {
        (editable || isSubmitting) && <>
          <div
            style={{
              display: 'flex',
              margin: '8px 0 0 0',
            }}
          >
            <div style={{
              flexGrow: 1,
            }}>&nbsp;</div>
            <Button
              label={formatButtonLabel(isUploading, isDirty, isSubmitting)}
              disabled={isUploading || isDirty || isSubmitting}
              theme='secondary'
              icon={(isUploading || isDirty || isSubmitting) && <CircularProgress theme='secondary'/>}
              onClick={submitIntro}
            />
          </div>
        </>
      }
    </div>
  </CollapsibleList>
}
