/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Metrics_AllMetricsQueryVariables = {};
export type Metrics_AllMetricsQueryResponse = {
    readonly metrics: ReadonlyArray<{
        readonly name: string;
        readonly " $fragmentRefs": FragmentRefs<"MetricBox_Metric">;
    }>;
};
export type Metrics_AllMetricsQuery = {
    readonly response: Metrics_AllMetricsQueryResponse;
    readonly variables: Metrics_AllMetricsQueryVariables;
};



/*
query Metrics_AllMetricsQuery {
  metrics {
    __typename
    name
    ...MetricBox_Metric
  }
}

fragment MetricBox_Metric on Metric {
  __isMetric: __typename
  name
  ... on TimeSeriesMetric {
    data(order: DESCENDING, limit: 1) {
      __typename
      dateMeasured
      ... on ScalarMeasurement {
        value
        unit {
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Metrics_AllMetricsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "metrics",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MetricBox_Metric"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Metrics_AllMetricsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "metrics",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isMetric"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": "DESCENDING"
                  }
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateMeasured",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SIDerrivedUnit",
                        "kind": "LinkedField",
                        "name": "unit",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "ScalarMeasurement",
                    "abstractKey": null
                  }
                ],
                "storageKey": "data(limit:1,order:\"DESCENDING\")"
              }
            ],
            "type": "TimeSeriesMetric",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "514083526f026af2ccdbd2f9048bdf84",
    "id": null,
    "metadata": {},
    "name": "Metrics_AllMetricsQuery",
    "operationKind": "query",
    "text": "query Metrics_AllMetricsQuery {\n  metrics {\n    __typename\n    name\n    ...MetricBox_Metric\n  }\n}\n\nfragment MetricBox_Metric on Metric {\n  __isMetric: __typename\n  name\n  ... on TimeSeriesMetric {\n    data(order: DESCENDING, limit: 1) {\n      __typename\n      dateMeasured\n      ... on ScalarMeasurement {\n        value\n        unit {\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'dc67f65f3a684abd758c907a948603b2';
export default node;
