import { Typography } from '@rmwc/typography';
import graphql from 'babel-plugin-relay/macro';
import createDomPurify from 'dompurify';
import { useMemo } from 'react';
import { useSubscription } from 'react-relay';
import { queue } from '../Snackbar/queue';
import { YouTubeCommentSnackbarHandlerSubscription, YouTubeCommentSnackbarHandlerSubscriptionResponse } from './__generated__/YouTubeCommentSnackbarHandlerSubscription.graphql';

import '@rmwc/snackbar/styles';

const subscription = graphql`
  subscription YouTubeCommentSnackbarHandlerSubscription {
    youTubeCommentCreated {
      id
      text
      publishedAt
      parent {
        __typename
        ... on YouTubeVideo {
          videoId
          title
        }
      }
    }
  }
`;

const sanitizeCommentBody = (comment: YouTubeCommentSnackbarHandlerSubscriptionResponse['youTubeCommentCreated']): string => {
  const DOMPurify = createDomPurify(window);

  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noreferrer');
      // If parent is a youtube video, we can inject a comment reference to the URL, as long as it is the same video
      // and no comment reference already exists.
      if (comment.parent.__typename === 'YouTubeVideo') {
        const hrefAttribute = node.getAttribute('href');
        if (hrefAttribute) {
          const url = new URL(hrefAttribute);
          if (url.host === 'www.youtube.com' && url.pathname === '/watch') {
            const params = url.searchParams;
            if (params.has('v') && params.get('v') === comment.parent.videoId && !params.has('lc')) {
              params.append('lc', comment.id);
              node.setAttribute('href', url.toString());
            }
          }
        }
      }
    }
    // set non-HTML/MathML links to xlink:show=new
    if (
      !node.hasAttribute('target') &&
      (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
    ) {
      node.setAttribute('xlink:show', 'new');
    }
  });

  return DOMPurify.sanitize(comment.text);
}

const SECOND = 1000;
const FIVE_SECONDS = 5 * SECOND;
const MINUTE = 60 * SECOND;
const FIVE_MINUTES = 5 * MINUTE;


export default function YouTubeCommentSnackbarHandler() {
  const config = useMemo(() => ({
    subscription,
    onNext: (payload: YouTubeCommentSnackbarHandlerSubscriptionResponse | null | undefined) => {
      if (!payload) {
        return;
      }

      const comment = payload.youTubeCommentCreated;

      // We send comment notifications five minutes later than they were commented because we only check that frequently
      // and we don't want multiple comments that occurred in the time period to show up simultaneously.
      const notifyAt = Date.parse(comment.publishedAt) + FIVE_MINUTES;
      const notificationTimeDeltaMs = notifyAt - Date.now();

      if (comment.parent.__typename === 'YouTubeVideo') {
        const video = comment.parent;
        const commentLink = `https://youtube.com/watch?v=${video.videoId}&lc=${comment.id}`;
        setTimeout(() => {
          queue.notify({
            actions: [{
              title: 'View Comment On YouTube',
              onClick: () => {
                window.open(commentLink, '_blank', 'noreferrer');
              },
              theme: 'secondary',
            }],
            body: <div
              style={{
                wordWrap: 'break-word',
                maxHeight: '500px',
                overflow: 'hidden',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: sanitizeCommentBody(comment) }}
            />,
            timeout: FIVE_SECONDS,
            title: <Typography use='headline6'>
              Someone just commented on&nbsp;
              <a
                href={commentLink}
                target='_blank'
                rel='noreferrer'
                style={{
                  color: 'var(--mdc-theme-secondary)',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                }}
              >
                {video.title}
              </a>
              :
            </Typography>,
          });
        }, notificationTimeDeltaMs < 0 ? 0 : notificationTimeDeltaMs);
      }
    },
    variables: {},
  }), []);
  useSubscription<YouTubeCommentSnackbarHandlerSubscription>(config);
  return <></>;
};
