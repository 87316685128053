import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      <p>Welcome!</p>
      <p>I hope it's obvious that this website is not done yet, but in case it isn't: this website is not done yet.</p>
      <p>If you're here to submit an intro, <Link to="/intro">click here!</Link></p>
    </div>
  );
}

export default Home;
