import { Switch, Route } from 'react-router-dom';
import WingNight from './WingNight';

function Overlays() {
  return (
    <Switch>
      <Route path='/overlays/wing-night/'>
        <WingNight />
      </Route>
    </Switch>
  );
}

export default Overlays;
