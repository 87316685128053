/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type YouTubeCommentSnackbarHandlerSubscriptionVariables = {};
export type YouTubeCommentSnackbarHandlerSubscriptionResponse = {
    readonly youTubeCommentCreated: {
        readonly id: string;
        readonly text: string;
        readonly publishedAt: string;
        readonly parent: {
            readonly __typename: "YouTubeVideo";
            readonly videoId: string;
            readonly title: string;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
    };
};
export type YouTubeCommentSnackbarHandlerSubscription = {
    readonly response: YouTubeCommentSnackbarHandlerSubscriptionResponse;
    readonly variables: YouTubeCommentSnackbarHandlerSubscriptionVariables;
};



/*
subscription YouTubeCommentSnackbarHandlerSubscription {
  youTubeCommentCreated {
    id
    text
    publishedAt
    parent {
      __typename
      ... on YouTubeVideo {
        videoId
        title
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "YouTubeComment",
    "kind": "LinkedField",
    "name": "youTubeCommentCreated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publishedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "type": "YouTubeVideo",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "YouTubeCommentSnackbarHandlerSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "YouTubeCommentSnackbarHandlerSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "69f847cf95a55c040144e7fa02b78d28",
    "id": null,
    "metadata": {},
    "name": "YouTubeCommentSnackbarHandlerSubscription",
    "operationKind": "subscription",
    "text": "subscription YouTubeCommentSnackbarHandlerSubscription {\n  youTubeCommentCreated {\n    id\n    text\n    publishedAt\n    parent {\n      __typename\n      ... on YouTubeVideo {\n        videoId\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '72c47d643444563cf5ae1d6061ae203e';
export default node;
