import { Switch, Route } from 'react-router-dom';
import RequireAuthentication from '../component/RequireAuthentication';
import Admin from './Admin';
import { Login, Logout, auth0Connections } from './Auth0Redirects';

import Episodes from './Episodes';
import Home from './Home';
import Intro from './Intro';
import Me from './Me';
import Metrics from './Metrics';

function Pages() {
  return (
    <Switch>
      <Route path='/metrics'>
        <Metrics />
      </Route>
      <Route path='/episodes'>
        <Episodes />
      </Route>
      <Route path='/login'>
        <Login connection={auth0Connections.USERNAME_PASSWORD} />
      </Route>
      <Route path='/logout'>
        <Logout />
      </Route>
      <Route path='/me'>
        <RequireAuthentication>
          <Me />
        </RequireAuthentication>
      </Route>
      <Route path='/intro'>
        <Intro />
      </Route>
      <Route path='/admin'>
        <Admin />
      </Route>
      <Route path='/'>
        <Home />
      </Route>
    </Switch>
  );
}

export default Pages;
