/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type IntroUploadUrlInput = {
    filename: string;
    mimeType: string;
};
export type IntroUploader_S3UrlMutationVariables = {
    input: IntroUploadUrlInput;
};
export type IntroUploader_S3UrlMutationResponse = {
    readonly introUploadUrl: {
        readonly s3Url: string;
        readonly intro: {
            readonly id: string;
            readonly uploader: {
                readonly id: string;
                readonly intros: ReadonlyArray<{
                    readonly id: string;
                }>;
            };
            readonly " $fragmentRefs": FragmentRefs<"MyIntrosTab_IntroBoxFragment">;
        };
    };
};
export type IntroUploader_S3UrlMutation = {
    readonly response: IntroUploader_S3UrlMutationResponse;
    readonly variables: IntroUploader_S3UrlMutationVariables;
};



/*
mutation IntroUploader_S3UrlMutation(
  $input: IntroUploadUrlInput!
) {
  introUploadUrl(input: $input) {
    s3Url
    intro {
      id
      uploader {
        id
        intros {
          id
        }
      }
      ...MyIntrosTab_IntroBoxFragment
    }
  }
}

fragment MyIntrosTab_IntroBoxFragment on Intro {
  description
  id
  name
  state
  submittedAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "s3Url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "uploader",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Intro",
      "kind": "LinkedField",
      "name": "intros",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IntroUploader_S3UrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IntroUploadDetail",
        "kind": "LinkedField",
        "name": "introUploadUrl",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Intro",
            "kind": "LinkedField",
            "name": "intro",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MyIntrosTab_IntroBoxFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IntroUploader_S3UrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IntroUploadDetail",
        "kind": "LinkedField",
        "name": "introUploadUrl",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Intro",
            "kind": "LinkedField",
            "name": "intro",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uploadPercentage",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2066f513b7afc28ffc6a1656ad47e75",
    "id": null,
    "metadata": {},
    "name": "IntroUploader_S3UrlMutation",
    "operationKind": "mutation",
    "text": "mutation IntroUploader_S3UrlMutation(\n  $input: IntroUploadUrlInput!\n) {\n  introUploadUrl(input: $input) {\n    s3Url\n    intro {\n      id\n      uploader {\n        id\n        intros {\n          id\n        }\n      }\n      ...MyIntrosTab_IntroBoxFragment\n    }\n  }\n}\n\nfragment MyIntrosTab_IntroBoxFragment on Intro {\n  description\n  id\n  name\n  state\n  submittedAt\n}\n"
  }
};
})();
(node as any).hash = '429b9d844a24b6760b8a6d025c827fa4';
export default node;
