/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EpisodeRole = "AUDIO" | "CAMERA" | "COLOR" | "EDITING" | "GRAPHICS" | "MUSIC" | "QUALITY_CONTROL" | "%future added value";
export type Episode_episodeByIdQueryVariables = {
    episodeId: string;
};
export type Episode_episodeByIdQueryResponse = {
    readonly episode: {
        readonly id: string;
        readonly credits: ReadonlyArray<{
            readonly appearedAs: string;
            readonly person: {
                readonly id: string;
                readonly name: string;
            };
            readonly roles: ReadonlyArray<EpisodeRole>;
        }>;
        readonly title: string;
        readonly videos: ReadonlyArray<{
            readonly __typename: "YouTubeVideo";
            readonly videoId: string;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }>;
        readonly seasonPlacement: {
            readonly number: number;
            readonly season: {
                readonly number: number;
            };
        };
        readonly previousEpisode: {
            readonly " $fragmentRefs": FragmentRefs<"EpisodeLink_Episode">;
        } | null;
        readonly nextEpisode: {
            readonly " $fragmentRefs": FragmentRefs<"EpisodeLink_Episode">;
        } | null;
    };
};
export type Episode_episodeByIdQuery = {
    readonly response: Episode_episodeByIdQueryResponse;
    readonly variables: Episode_episodeByIdQueryVariables;
};



/*
query Episode_episodeByIdQuery(
  $episodeId: ID!
) {
  episode(id: $episodeId) {
    id
    credits {
      appearedAs
      person {
        id
        name
      }
      roles
    }
    title
    videos {
      __typename
      ... on YouTubeVideo {
        videoId
      }
    }
    seasonPlacement {
      number
      season {
        number
      }
    }
    previousEpisode {
      ...EpisodeLink_Episode
      id
    }
    nextEpisode {
      ...EpisodeLink_Episode
      id
    }
  }
}

fragment EpisodeLink_Episode on Episode {
  id
  title
  state
  datePublished
  seasonPlacement {
    season {
      number
    }
    number
  }
  videos {
    __typename
    ... on YouTubeVideo {
      videoId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "episodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "episodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EpisodeCredit",
  "kind": "LinkedField",
  "name": "credits",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appearedAs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "videos",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoId",
          "storageKey": null
        }
      ],
      "type": "YouTubeVideo",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Season",
  "kind": "LinkedField",
  "name": "season",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SeasonPlacement",
  "kind": "LinkedField",
  "name": "seasonPlacement",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EpisodeLink_Episode"
  }
],
v10 = [
  (v2/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "datePublished",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SeasonPlacement",
    "kind": "LinkedField",
    "name": "seasonPlacement",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Episode_episodeByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Episode",
        "kind": "LinkedField",
        "name": "episode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Episode",
            "kind": "LinkedField",
            "name": "previousEpisode",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Episode",
            "kind": "LinkedField",
            "name": "nextEpisode",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Episode_episodeByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Episode",
        "kind": "LinkedField",
        "name": "episode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Episode",
            "kind": "LinkedField",
            "name": "previousEpisode",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Episode",
            "kind": "LinkedField",
            "name": "nextEpisode",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9440c602d4b1e8f0ece6ffee0daf77f4",
    "id": null,
    "metadata": {},
    "name": "Episode_episodeByIdQuery",
    "operationKind": "query",
    "text": "query Episode_episodeByIdQuery(\n  $episodeId: ID!\n) {\n  episode(id: $episodeId) {\n    id\n    credits {\n      appearedAs\n      person {\n        id\n        name\n      }\n      roles\n    }\n    title\n    videos {\n      __typename\n      ... on YouTubeVideo {\n        videoId\n      }\n    }\n    seasonPlacement {\n      number\n      season {\n        number\n      }\n    }\n    previousEpisode {\n      ...EpisodeLink_Episode\n      id\n    }\n    nextEpisode {\n      ...EpisodeLink_Episode\n      id\n    }\n  }\n}\n\nfragment EpisodeLink_Episode on Episode {\n  id\n  title\n  state\n  datePublished\n  seasonPlacement {\n    season {\n      number\n    }\n    number\n  }\n  videos {\n    __typename\n    ... on YouTubeVideo {\n      videoId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9e14b90d981ed394d245688cc737e716';
export default node;
