import { Route, Switch } from "react-router";
import AllEpisodes from "./AllEpisodes";
import Episode from "./Episode";


function Episodes() {
  return <>
    <Switch>
      <Route path='/episodes/:episodeId'>
        <Episode />
      </Route>
      <Route path='/episodes'>
        <AllEpisodes />
      </Route>
    </Switch>
  </>
}

export default Episodes;
